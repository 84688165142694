<template>
	<div class="my-container p-all-20">
		<div class="top-box d-space-column">
			<!-- 头部背景 -->
			<div class="top-border-bg max-bg"></div>
			<!-- 侧边 -->
			<div class="side-border p-all-10">
			</div>
			<div class="my-box max-bg p-all m-bottom d-space-row justify-start" v-if="userInfo.level == 0">
				<div class="title-box d-center-row">
					<img src="../../assets/image/title-icon-1.png" alt="">
					<span class="m-left-right f-w">{{ i18n.title }}</span>
					<img src="../../assets/image/title-icon-2.png" alt="">
				</div>
				<div class="pool-logo m-right">
					<img src="../../assets/image/pool-logo.png" alt="">
				</div>
				<div class="pool-info d-space-column align-start">
					<span>{{ linkWallet }}</span>
				</div>
			</div>

			<div class="my-box2 max-bg p-all m-bottom d-space-row justify-start" v-if="userInfo.level > 0">
				<div class="title-box d-center-row">
					<img src="../../assets/image/title-icon-1.png" alt="">
					<span class="m-left-right f-w">{{ i18n.title }}</span>
					<img src="../../assets/image/title-icon-2.png" alt="">
				</div>

				<div class="identify-box d-center-row">
					<!-- <img :src="require('../../assets/image/level-'+userInfo.level+'.png')" alt=""> -->
					<img :src="require('../../assets/image/level-'+userInfo.level+'.png')" alt="">
				</div>
				<div class="bt-box d-space-row justify-start">

				<div class="pool-logo m-right">
					<img src="../../assets/image/pool-logo.png" alt="">
				</div>
				<div class="pool-info d-space-column align-start">
					<span>{{ linkWallet }}</span>
					<div class="node d-center-row m-top-10">
						<span v-if="userInfo.level == 1">{{ i18n.level1 }}</span>
						<span v-if="userInfo.level == 2">{{ i18n.level2 }}</span>
						<span v-if="userInfo.level == 3">{{ i18n.level3 }}</span>
						<span v-if="userInfo.level == 4">{{ i18n.level4 }}</span>
					</div>
				</div>
			</div>


			</div> 


			<div class="balance-box max-bg d-space-row m-top p-left-right-10">
				<div class="b-item max-bg d-around-column" @click="$router.push({ name: 'record', query: { type: 1 } })">
					<span class="f-36">{{ parseFloat(wallet.arc).toFixed(4) }}</span>
					<span class="f-26">LELE</span>
				</div>
				<div class="b-item max-bg d-around-column" @click="$router.push({ name: 'record', query: { type: 2 } })">
					<span class="f-36">{{ parseFloat(wallet.arb).toFixed(4) }}</span>
					<span class="f-26">ARB</span>
				</div>
				<!-- <div class="b-item max-bg d-around-column" @click="$router.push({ name: 'record', query: { type: 3 } })">
					<span class="f-36">{{wallet.usdt}}</span>
					<span class="f-26">USDT</span>
				</div> -->
				<div class="b-item max-bg d-around-column" @click="$router.push({ name: 'record', query: { type: 3 } })">
					<span class="f-36">{{ parseFloat(wallet.eth).toFixed(6) }}</span>
					<span class="f-26">ETH</span>
				</div>
				<div class="withdraw-box d-center-row">
					<div class="d-center-row max-bg" @click="$router.push({ name: 'withdraw' })">
						<span>{{ i18n.tx }}</span>
					</div>
				</div>
			</div>
			<div class="column-box d-space-row">
				<div class="c-1 max-bg" @click="$router.push({ name: 'invite' })">
					<span>{{ i18n.tjyj }}</span>
				</div>
				<div class="c-2 max-bg" @click="$router.push({ name: 'team' })">
					<span>{{ i18n.wdtd }}</span>
				</div>
				<div class="c-3 max-bg" @click="$router.push({ name: 'pool' })">
					<span>{{ i18n.aqzx }}</span>
				</div>
				<div class="c-4 max-bg">
					<span>{{ i18n.tcdl }}</span>
				</div>
			</div>
			<div class="bottom-border max-bg"></div>
		</div>
	</div>
</template>

<script>
import { groupParams } from "@/api/account.js";
import {
	mapGetters,
} from "vuex";

import { balance } from "@/api/account.js";
import { accountInfo } from "@/api/account.js";
export default {
	data() {
		return {
			wallet: {
				arc: 0,
				arb: 0,
				usdt: 0,
				eth: 0
			},
			params: {},
			userInfo:{}
		};
	},
	computed: {
		...mapGetters(['address']),
		linkWallet() {
			if (this.address) {
				return this.$dapp.ellipseAddress(this.address, 5)
			}
		},
		i18n() {
			return this.$t('my')
		}
	},
	created() {
		this.$dapp.useAddress(() => {
			this.$nextTick(() => {
				this.getBalance()
				this.getParams()
				this.getUserInfo()
			})
		})

	},
	methods: {
		async getUserInfo() {
			const { err, res } = await accountInfo();
			this.userInfo = res;
		},
		async getParams() {
			const {
				err,
				res
			} = await groupParams();
			if (err === null) {
				this.params = res;
			}
		},
		async getBalance() {
			const {
				err,
				res
			} = await balance();
			if (err === null) {
				this.wallet = res;
			}
		},
	}

}
</script>

<style lang="scss" scoped>
.my-container {
	.top-box {
		width: 100%;
		// height: auto;
		position: relative;
		box-sizing: border-box;
		padding-top: 50px;

		.top-border-bg {
			width: 100%;
			height: 300px;
			background: url('../../assets/image/border-bg.png');
			position: absolute;
			left: 0;
			top: 0;
		}

		.side-border {
			width: 100%;
			height: 1150px;
			border-left: 2px solid #4BB005;
			border-right: 2px solid #4BB005;
			position: absolute;
			left: 0;
			bottom: 0;
		}

		.my-box {
			width: 643px;
			height: 279px;
			background: url('../../assets/image/pool-bg.png');
			position: relative;
			box-sizing: border-box;
			padding-top: 60px;

			.title-box {
				width: 643px;
				height: 50px;
				// border: 1px solid red;
				position: absolute;
				top: -26px;
				left: 0;

				img {
					width: 54px;
					height: 30px;
				}

				font-size: 42px;
			}
		}

		.my-box2 {
			width: 643px;
			height: 952px;
			background: url('../../assets/image/identify-back.png');
			position: relative;
			box-sizing: border-box;
			padding-top: 60px;

			.bt-box{
				position: absolute;
				bottom: 50px;
			}
			.title-box {
				width: 643px;
				height: 50px;
				// border: 1px solid red;
				position: absolute;
				top: -26px;
				left: 0;

				img {
					width: 54px;
					height: 30px;
				}

				font-size: 42px;
			}

			.identify-box {
				width: 643px;
				height: 700px;
				// border: 1px solid red;
				position: absolute;
				top: 20px;
				left: 0;

				img {
					width: 580px;
					height: 688px;
				}

				font-size: 42px;
			}
		}

		.pool-logo {
			img {
				width: 147px;
				height: 147px;
			}
		}

		.pool-info {

			.node {
				color: gold;
				width: auto;
				height: 49px;
				padding: 0 20px;
				background: rgba(243, 101, 18, 0.5);
				border-radius: 10px;
			}
		}

		.balance-box {
			width: 643px;
			height: 350px;
			background: url('../../assets/image/balance-box-bg.png');
			position: relative;
			padding: 30px;
			flex-wrap: wrap;

			.b-item {
				width: 280px;
				height: 118px;
				background: url('../../assets/image/balance-bg.png');
				box-sizing: border-box;
				padding: 10px;

				span:nth-child(1) {
					color: #F1FF58;
				}
			}

			.withdraw-box {
				width: 100%;
				height: 60px;
				position: absolute;
				left: 0;
				bottom: -30px;
				z-index: 9;

				&>div {
					width: 181px;
					height: 57px;
					background: url('../../assets/image/my-node-bg.png');
				}
			}
		}

		.column-box {
			width: 643px;
			flex-wrap: wrap;
			margin-top: 60px;
			position: relative;
			z-index: 9;

			&>div {
				width: 305px;
				height: 339px;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;
				box-sizing: border-box;
				padding-bottom: 60px;
				margin-bottom: 30px;
				text-align: center;
			}

			.c-1 {
				background: url('../../assets/image/c-1-bg.png');
			}

			.c-2 {
				background: url('../../assets/image/c-2-bg.png');
			}

			.c-3 {
				background: url('../../assets/image/c-3-bg.png');
			}

			.c-4 {
				background: url('../../assets/image/c-4-bg.png');
			}
		}
	}

	.bottom-border {
		width: 100%;
		height: 2px;
		background: url('../../assets/image/bottom-border.png');
	}
}
</style>